<script>
export default {
	name: 'CheckIn',
	inheritAttrs: false,
};
</script>

<script setup>
import CTAButton from '~/aem-components/CTAButton.vue';
import MobileSubpage from '~/components/BookingWidget/MobileSubpage.vue';
import useBookingWidgetSubpage from '~/logic/composables/useBookingWidgetSubpage.js';
import TextField from '~/components/form/TextField.vue';
import Tooltip from '~/components/Tooltip.vue';
import FormWrapper from '~/components/form/FormWrapper.vue';
import yup from 'mh-yup';
import { useGlobalAEMState } from '~/logic/aem/global-aem-state.js';
import { isMobileViewport } from '~/logic/composables/breakpoints.js';

const props = defineProps({
	bretnLabel: { type: String, default: '' },
	bretnMandatory: { type: Boolean, default: false },
	bretnPalceholder: { type: String, default: '' },
	bretnShortDescription: { type: String, default: '' },
	bretnCharLimit: { type: Number, default: 0 },
	bretnCharLimitErrorMessage: { type: String, default: '' },
	bretnMandatoryErrorMessage: { type: String, default: '' },

	checkInButtonLabel: { type: String, default: '' },
	checkInDescription: { type: String, default: '' },
	checkInNote: { type: String, default: '' },
	checkInToolTipLabel: { type: String, default: '' },
	checkInToolTip: { type: String, default: '' },

	lastNameLabel: { type: String, default: '' },
	lastNameMandatory: { type: Boolean, default: false },
	lastNameMandatoryErrorMessage: { type: String, default: '' },
	lastNamePalceholder: { type: String, default: '' },
	lastNameShortDescription: { type: String, default: '' },
	refxAPIURL: { type: String, default: '' },
	language: { type: String, default: '' },

	componentIdAA: { type: String, default: '' },

	// Prop for HiddenSeamlessBookingWidget component
	isHideInDesktop: { type: Boolean, default: false },
});

const { isEditorMode } = useGlobalAEMState();

const emit = defineEmits([
	// 'update:modelValue',
]);

const rootEl = ref(null);
const formRef = ref(null);
const bretnFieldValue = ref('');
const lastNameFieldValue = ref('');

const { isMobileSubpageVisible } = useBookingWidgetSubpage({
	hashKey: ['check-in', 'check-in-tab'],
	rootEl,
});

const bretnFieldValidation = computed(() => {
	if (props.bretnMandatory) {
		return yup
			.string()
			.required(props.bretnMandatoryErrorMessage)
			.max(props.bretnCharLimit, props.bretnCharLimitErrorMessage);
	}

	return null;
});

const lastNameFieldValidation = computed(() => {
	if (props.lastNameMandatory) {
		return yup
			.string()
			.required(props.lastNameMandatoryErrorMessage);
	}

	return null;
});

const handleSubmitValid = (values, actions) => {
	console.log('✅ handleSubmitValid values = ', values);
	formSubmission();
};

const handleSubmitInvalid = ({ values, errors, results, evt }) => {
	console.log('❌ handleSubmitInvalid errors = ', errors);
};

const formSubmission = () => {

	if (bretnFieldValue.value && lastNameFieldValue.value) {
		const params = {
			lastName: lastNameFieldValue.value,
			identifier: bretnFieldValue.value,
			...(props.language ? {
				lang: `${props.language}`,
			} : {}),
		};

		const url = new URL(props.refxAPIURL);

		Object.keys(params).forEach((key) => {
			url.searchParams.append(key, params[key]);
		});

		// Redirect to REFX Check-in
		window.location.href = url.toString();
	}
};

</script>

<template>
<div
	v-if="!props.isHideInDesktop && !isMobileViewport"
	ref="rootEl"
	class="CheckIn booking-widget-subcomponent"
	:class="{
		'isEditorMode': isEditorMode,
	}"
>
	<div class="generic-container">
		<h5 v-if="isEditorMode" class="title-editor-mode">
			<icon-fas-plane class="mr-2 fill-current inline-block" />
			<span>Booking Widget: Check-in</span>
		</h5>

		<FormWrapper
			ref="formRef"
			class="check-in-wrapper"
			@submit-valid="handleSubmitValid"
			@submit-invalid="handleSubmitInvalid"
		>
			<p v-html-sanitize="props.checkInDescription"></p>
			<div class="check-in-tooltip flex items-center mt-6">
				<Tooltip>
					<template #default>
						<icon-fas-circle-question class="fill-primary-blue-base" />
					</template>
					<template #mobile-title>
						{{ props.checkInToolTipLabel }}
					</template>
					<template #tooltip-content>
						<p v-html-sanitize="props.checkInToolTip"></p>
					</template>
				</Tooltip>
				<span class="ml-3">{{ props.checkInToolTipLabel }}</span>
			</div>
			<div class="check-in-form mt-6 flex gap-x-6 md:flex-col md:gap-y-4">
				<div class="flex-grow">
					<TextField
						v-model="bretnFieldValue"
						:placeholder="props.bretnPalceholder"
						:ariaLabel="props.bretnLabel"
						:validation="bretnFieldValidation"
						showBtnClear
						name="bretnField"
						variant="booking-widget"
					/>
					<template v-if="props.bretnShortDescription">
						<p
							v-if="!formRef?.errors['bretnField']"
							v-html-sanitize="props.bretnShortDescription"
							class="text-sm text-[#61666F] mt-1"
						></p>
					</template>
				</div>
				<div class="w-4/10 md:w-full">
					<TextField
						v-model="lastNameFieldValue"
						:placeholder="props.lastNamePalceholder"
						:ariaLabel="props.lastNameLabel"
						:validation="lastNameFieldValidation"
						:autoTransformToTitlecase="true"
						showBtnClear
						name="breLastNameField"
						variant="booking-widget"
					/>
					<template v-if="props.lastNameShortDescription ">
						<p
							v-if="!formRef?.errors['breLastNameField']"
							v-html-sanitize="props.lastNameShortDescription"
							class="text-sm text-[#61666F] mt-1"
						></p>
					</template>
				</div>
				<div class="flex-none >lg:w-[max-content] md:mt-4">
					<CTAButton
						v-aa="[
							{
								clickName: props.checkInButtonLabel,
								clickComponentType: 'Button',
								componentName: 'CheckIn',
								componentID: props.componentIdAA,
							},
							{
								name: props.checkInButtonLabel,
								type: 'exit',
							},
						]"
						:ctaText="props.checkInButtonLabel"
						:disabled="!bretnFieldValue || !lastNameFieldValue"
						:isSubmit="true"
						:sizeType="isMobileViewport ? 'small' : 'large'"
						ctaSizing="fluid"
					/>
				</div>
			</div>
		</FormWrapper>

		<div
			v-if="props.checkInNote"
			class="flex mt-6"
		>
			<div class="rounded-xl bg-primary-blue-extralight flex-grow px-5 py-4 flex">
				<div class="basis-6 flex-none mr-3 rtl:ml-3 rtl:mr-0 top-0.5 relative">
					<icon-fas-circle-info class="fill-primary-blue-base" aria-hidden="true" />
				</div>
				<div class="typo-body-text-2">
					<span v-html-sanitize="props.checkInNote"></span>
				</div>
			</div>
		</div>
	</div>
</div>

<MobileSubpage
	:isVisible="isMobileSubpageVisible"
	class="text-white"
	style="--overlay-container-bg-color: var(--secondary-blue-extradark);"
	@update:is-visible="isMobileSubpageVisible = $event"
>

	<template #banner-title>
		<h1 class="text-center">{{ $t('Check In') }}</h1>
	</template>

	<template #default>
		<div class="generic-container my-4">
			<h5 v-if="isEditorMode" class="title-editor-mode">
				<icon-fas-plane class="mr-2 fill-current inline-block" />
				<span>Booking Widget: Check-in</span>
			</h5>

			<FormWrapper
				ref="formRef"
				class="check-in-wrapper"
				@submit-valid="handleSubmitValid"
				@submit-invalid="handleSubmitInvalid"
			>
				<p v-html-sanitize="props.checkInDescription" class="text-sm"></p>
				<div class="check-in-tooltip flex items-center mt-6">
					<Tooltip>
						<template #default>
							<icon-fas-circle-question class="fill-primary-blue-base" />
						</template>
						<template #mobile-title>
							{{ props.checkInToolTipLabel }}
						</template>
						<template #tooltip-content>
							<p v-html-sanitize="props.checkInToolTip"></p>
						</template>
					</Tooltip>
					<span class="ml-3 text-sm">{{ props.checkInToolTipLabel }}</span>
				</div>
				<div class="check-in-form mt-6 flex gap-x-6 md:gap-y-4 xl:flex-wrap lg:flex-col">
					<div class="flex-grow">
						<TextField
							v-model="bretnFieldValue"
							:placeholder="props.bretnPalceholder"
							:ariaLabel="props.bretnLabel"
							:validation="bretnFieldValidation"
							showBtnClear
							name="bretnField"
							variant="booking-widget"
						/>
						<template v-if="props.bretnShortDescription">
							<p
								v-if="!formRef?.errors['bretnField']"
								v-html-sanitize="props.bretnShortDescription"
								class="text-sm text-[#61666F] mt-1"
							></p>
						</template>
					</div>
					<div class="w-full">
						<TextField
							v-model="lastNameFieldValue"
							:placeholder="props.lastNamePalceholder"
							:ariaLabel="props.lastNameLabel"
							:validation="lastNameFieldValidation"
							:autoTransformToTitlecase="true"
							showBtnClear
							name="breLastNameField"
							variant="booking-widget"
						/>
						<template v-if="props.lastNameShortDescription ">
							<p
								v-if="!formRef?.errors['breLastNameField']"
								v-html-sanitize="props.lastNameShortDescription"
								class="text-sm text-[#61666F] mt-1"
							></p>
						</template>
					</div>
					<div class="flex-none >lg:w-[max-content] mt-4">
						<CTAButton
							v-aa="[
								{
									clickName: props.checkInButtonLabel,
									clickComponentType: 'Button',
									componentName: 'CheckIn',
									componentID: props.componentIdAA,
								},
								{
									name: props.checkInButtonLabel,
									type: 'exit',
								},
							]"
							:ctaText="props.checkInButtonLabel"
							:disabled="!bretnFieldValue || !lastNameFieldValue"
							:isSubmit="true"
							:sizeType="isMobileViewport ? 'small' : 'large'"
							ctaSizing="fluid"
						/>
					</div>
				</div>
			</FormWrapper>

			<div
				v-if="props.checkInNote"
				class="flex mt-6"
			>
				<div class="rounded-xl bg-primary-blue-extralight flex-grow px-5 py-4 flex">
					<div class="basis-6 flex-none mr-3 rtl:ml-3 rtl:mr-0 top-0.5 relative">
						<icon-fas-circle-info class="fill-primary-blue-base" aria-hidden="true" />
					</div>
					<div class="typo-body-text-2">
						<span v-html-sanitize="props.checkInNote"></span>
					</div>
				</div>
			</div>
		</div>
	</template>

</MobileSubpage>
</template>



<style scoped lang="scss">
@use 'sass:color';
@use '~/styles/partials/_var.scss';

.CheckIn {
	// no need to make this align with its parent switch tab, for now
	/* --tab-width: 205px;
	--tab-count: 6;

	max-width: calc(var(--tab-width) * var(--tab-count) * 0.92);
	margin: 0 auto; */
}

</style>
